@font-face {
    font-family: 'Aeonik';
    src: url('aeonik-medium.eot');
    src: url('aeonik-medium.eot?#iefix') format('embedded-opentype'),
    url('aeonik-medium.woff2') format('woff2'),
    url('aeonik-medium.woff') format('woff'),
    url('aeonik-medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('aeonik-regular.eot');
    src: url('aeonik-regular.eot?#iefix') format('embedded-opentype'),
    url('aeonik-regular.woff2') format('woff2'),
    url('aeonik-regular.woff') format('woff'),
    url('aeonik-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('aeonik-regularitalic.eot');
    src: url('aeonik-regularitalic.eot?#iefix') format('embedded-opentype'),
    url('aeonik-regularitalic.woff2') format('woff2'),
    url('aeonik-regularitalic.woff') format('woff'),
    url('aeonik-regularitalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}