/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 5, 2022 */


@font-face {
    font-family: 'IvarDisplay';
    src: url('ivardisplay-italic.eot');
    src: url('ivardisplay-italic.eot?#iefix') format('embedded-opentype'),
    url('ivardisplay-italic.woff2') format('woff2'),
    url('ivardisplay-italic.woff') format('woff'),
    url('ivardisplay-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'IvarDisplay';
    src: url('ivardisplay-regular.eot');
    src: url('ivardisplay-regular.eot?#iefix') format('embedded-opentype'),
    url('ivardisplay-regular.woff2') format('woff2'),
    url('ivardisplay-regular.woff') format('woff'),
    url('ivardisplay-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
